
@font-face {
    font-family: 'Gaegu Regular';
    src: url(../src/fonts/Gaegu-Regular.ttf);
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gaegu Bold';
    src: url(../src/fonts/Gaegu-Bold.ttf);
    font-weight: 600;
    font-style: normal;

}