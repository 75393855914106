.checkout {
  margin: 1rem;
}
.checkout__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 1rem;
}
.checkout__title {
  font-size: 1.5rem;
}
.checkout__contact-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.checkout__contact-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.checkout input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  font-size: 1rem;
}
.checkout__CTA {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.checkout__CTA--btn {
  box-shadow: inset 0px 1px 0px 0px #ffffff;
  background: linear-gradient(to bottom, #f9f9f9 5%, #e9e9e9 100%);
  background-color: #f9f9f9;
  border-radius: 6px;
  border: 1px solid #dcdcdc;
  font-family: "Gaegu Regular", sans-serif;
  font-size: 1.5rem;
  display: inline-block;
  cursor: pointer;
  color: #666666;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ffffff;
}
.checkout__CTA--btn:hover {
  background: linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
  background-color: #e9e9e9;
}
.checkout__CTA--btn:active {
  position: relative;
  top: 1px;
}/*# sourceMappingURL=checkout.css.map */