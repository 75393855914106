@font-face {
  font-family: "Gaegu Regular";
  src: url(../src/fonts/Gaegu-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gaegu Bold";
  src: url(../src/fonts/Gaegu-Bold.ttf);
  font-weight: 600;
  font-style: normal;
}
/* global styling */
* {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
  display: flex;
}

a:visited {
  color: inherit;
}

body {
  font-family: "Gaegu Regular", sans-serif;
  background-color: #F7F8F9;
}

hr {
  background-color: #AFAFAF;
  border: none;
  height: 1px;
}/*# sourceMappingURL=App.css.map */