.product {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-around;
  gap: 1rem;
}
.product__card:hover .product__card-inner {
  transform: rotateY(180deg);
}
.product__card {
  background-color: transparent;
  width: 15rem;
  height: 20rem;
  perspective: 1000px;
}
.product__card-item--image {
  margin-top: 0rem;
  width: 8rem;
}
.product__card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.product__card-inner--front, .product__card-inner--back {
  position: absolute;
  padding-top: 3rem;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}
.product__card-inner--back {
  transform: rotateY(180deg);
}

.my-custom-button {
  align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform, opacity;
  z-index: 0;
}
.my-custom-button:hover {
  background: #F6F9FE;
  color: #174ea6;
}
.my-custom-button:active {
  box-shadow: 0 4px 4px 0 rgba(60, 64, 67, 0.3), 0 8px 12px 6px rgba(60, 64, 67, 0.15);
  outline: none;
}
.my-custom-button:focus {
  outline: none;
  border: 2px solid #4285f4;
}
.my-custom-button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0, rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}
.my-custom-button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0, rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}
.my-custom-button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0, rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}
.my-custom-button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0, rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}
.my-custom-button:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0, rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}/*# sourceMappingURL=ProductList.css.map */